export default function TrustpilotWidget () {
  return (
    <>
      <div
        className="trustpilot-widget"
        data-locale="de-DE"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="64a40a1423fb11000bf103b2"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="dark"
        data-min-review-count="10"
        data-style-alignment="center"
      >
        <a
          href="https://de.trustpilot.com/review/cadsoma.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white"
        >
          Trustpilot
        </a>
      </div>
    </>
  )
}
