import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useQuery } from '@apollo/client'
import { GET_APPLICATIONS } from '../services/graphql/queries/getApplications'
import useTranslatedRoutes from '../services/translatedRoutes'
import { useRouter } from 'next/router'
import TrustpilotWidget from './TrustpilotWidget'

export default function Footer () {
  const { t } = useTranslation(['footer', 'applications'])

  const router = useRouter()
  const language = router.locale.toUpperCase()
  const { getSoftwareUrl } = useTranslatedRoutes(language)

  const { data } = useQuery(GET_APPLICATIONS, {
    variables: {
      active_only: true
    }
  })

  return (
    <footer className={'pagefooter__bgimage py-5'}>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-md-8 col-lg-5 mb-4 mb-md-0">
            <span className="h2 fw-bold text-white d-block">{t('title')}</span>
          </div>
          <div className="col-12 col-md-4 col-lg-6">
            <nav>
              <ul className="row list-unstyled fs-6 lh-lg">
                <strong className="text-white text-uppercase mb-3">CADSOMA.com</strong>
                <li className="nav-item col-6 col-md-12 col-lg-6">
                  <Link href="/about">
                    <a className="nav-link text-white">{t('links.about')}</a>
                  </Link>
                </li>
                <li className="nav-item col-6 col-md-12 col-lg-6">
                  <a
                    href="https://cad-deutschland.de"
                    className="nav-link text-white"
                    target="_blank"
                    rel="noreferrer"
                  >{t('links.cad-germany')}</a>
                </li>
                <li className="nav-item col-6 col-md-12 col-lg-6">
                  <a
                    target="_blank"
                    href="https://www.mervisoft-gmbh.de/"
                    className="nav-link text-white"
                    rel="noreferrer"
                  >Mervisoft GmbH</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className={'pagefooter_separator my-3 mb-4 my-lg-5'}></div>
        <div className="row justify-content-between">
          <div className="col-12 mb-4 mb-lg-5">
            <div className="row">
              <strong className="text-white col-12 text-uppercase fs-6  mb-3">
                {t('links.software')}
              </strong>
            </div>
            <nav>
              <ul className="row p-0 ">
                {
                  data && data.applications.map((application, index) => (
                    <li className="nav-item fs-6 lh-lg col-6 col-md-4 col-lg-3 col-xl-2"
                      key={index}
                    >
                      <Link href={getSoftwareUrl(application)}>
                        <a className="nav-link text-white">{t(application.name, { ns: 'application' })}</a>
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </nav>
          </div>
          <div className="col-6 col-lg-3 order-lg-1">
            <div className="row">
              <strong className="text-white col-12 text-uppercase fs-6 mb-3">{t('links.partners.title')}</strong>
            </div>
            <nav className="row">
              <ul className="fs-6 lh-lg col-12 d-lg-flex gap-3">
                <li className="nav-item">
                  <Link href="/become-a-seller">
                    <a className="nav-link text-white">{t('links.partners.start_selling')}</a>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="/distributors">
                    <a className="nav-link text-white">{t('links.partners.distributors')}</a>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-6 col-lg-4 order-lg-3">
            <div className="row">
              <strong className="text-white col-12 text-uppercase fs-6 mb-3">{t('links.service.title')}</strong>
            </div>
            <nav className="row">
              <ul className="fs-6 lh-lg col-12 d-lg-flex gap-3">
                <li className="nav-item">
                  <Link href="/privacy-policy">
                    <a className="nav-link text-white">{t('links.service.policy')}</a>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="/imprint">
                    <a className="nav-link text-white">{t('links.service.imprint')}</a>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="/terms-and-conditions-seller">
                    <a className="nav-link text-white">{t('links.service.terms.seller')}</a>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="/terms-and-conditions-user">
                    <a className="nav-link text-white">{t('links.service.terms.user')}</a>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-12 col-lg-5 order-lg-2">
            <TrustpilotWidget />
          </div>
        </div>
      </div>
    </footer>
  )
}
